import docReady from '../../../assets/js/docReady.js';

class SimOnlyPrefillComponent {
    constructor(element) {
        this.element = element;

        this.data = {
            toggle: null,
            inputField: null,
            labelField: null,
            optionList: null,
        };

        this.minutes = {
            toggle: null,
            inputField: null,
            labelField: null,
            optionList: null,
        };
    }

    init() {
        this.bootstrap();

        this.updateSelection(this.data);
        this.updateSelection(this.minutes);

        this.data.toggle.addEventListener('input', () => {
            this.updateSelection(this.data);
        });
        this.minutes.toggle.addEventListener('input', () => {
            this.updateSelection(this.minutes);
        });
    }

    bootstrap() {
        this.data.toggle = this.element.querySelector('.slider.data .slider-input');
        this.data.inputField = this.element.querySelector('.selected-data-input');
        this.data.labelField = this.element.querySelector('.value.data');
        this.data.optionList = this.element.querySelectorAll('.datalist option');
        this.data.prefillValue = this.element.querySelector('.slider.data')
            .getAttribute('data-value');

        this.minutes.toggle = this.element.querySelector('.slider.minutes .slider-input');
        this.minutes.inputField = this.element.querySelector('.selected-minutes-input');
        this.minutes.labelField = this.element.querySelector('.value.minutes');
        this.minutes.optionList = this.element.querySelectorAll('.minuteslist option');
    }

    /* eslint class-methods-use-this: 0 */
    updateSelection(changedSlider) {
        const selectionIndex = changedSlider.toggle.value;
        const selectedOption = changedSlider.optionList[selectionIndex];
        changedSlider.inputField.value = selectedOption.value;
        changedSlider.labelField.innerHTML = selectedOption.getAttribute('label');
        const x = changedSlider.toggle.value > 4 ? changedSlider.toggle.value * (100 / changedSlider.optionList.length + 1) : changedSlider.toggle.value * (100 / changedSlider.optionList.length + 4);
        changedSlider.toggle.style.backgroundSize = `${x}% 100%`;
    }
}

docReady(() => {
    const simOnlyPrefillElements = document.querySelectorAll('.sim-only-prefill-component');
    simOnlyPrefillElements.forEach((element) => {
        const simOnlyPrefilComponent = new SimOnlyPrefillComponent(element);
        simOnlyPrefilComponent.init();
    });
});
